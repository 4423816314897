import styled from "styled-components"

const BaseButton = styled.button`
  border-radius: 4px;
  font-size: 1.6rem;
  padding: 0.8rem 2.4rem;
`

const Button = styled(BaseButton)`
  background: ${props => {
    if (props.outline) {
      return "none"
    }
    if (props.white) {
      return "white"
    }
    if (props.beige) {
      return "var(--beige)"
    }
    return "var(--blue)"
  }};
  border: ${props => {
    if (props.outline) {
      const border = "1px solid"
      if (props.white) {
        return `${border} white`
      }
      if (props.beige) {
        return `${border} var(--beige)`
      }
      return `${border} var(--blue)`
    }
    return "none"
  }};
  outline: none;
  color: ${props => {
    if (props.outline) {
      if (props.white) {
        return "white"
      }
      if (props.beige) {
        return "var(--beige)"
      }
      return "var(--blue)"
    }

    if (props.white) {
      return "var(--blue)"
    }
    return "white"
  }};
`

const Buttons = styled.button`
  border-radius: 4px;
  font-size: 1.6rem;
  padding: 0.8rem 2.4rem;
`

const PrimaryButtons = styled(Buttons)`
  background-color: ${props => {
    if (props.white) {
      return "white"
    }
    if (props.beige) {
      return "var(--beige)"
    }
    return "var(--blue)"
  }};
  color: ${props => {
    if (props.white) {
      return "var(--blue)"
    }
    return "white"
  }};
  border: none;
  outline: none;
`

const OutlinedButtons = styled(Buttons)`
  background: none;
  border: 1px solid
    ${props => {
      if (props.white) {
        return "white"
      }
      if (props.beige) {
        return "var(--beige)"
      }
      return "var(--blue)"
    }};
  color: ${props => {
    if (props.white) {
      return "white"
    }
    if (props.beige) {
      return "var(--beige)"
    }
    return "var(--blue)"
  }};
`

export { OutlinedButtons, PrimaryButtons, Button }
